<script lang="ts" setup>
useHead({
  title: 'Liquidity & Launch Designer ',
  htmlAttrs: {
    class: 'scroll-smooth',
  },
})

interface NavButton {
  label: string
  to: string
}

interface NavSection {
  title?: string
  buttons: NavButton[]
}

const route = useRoute()

const navSections = computed<NavSection[]>(() => {
  return route.path.endsWith('dashboard') ? dashboardNav : overviewNav
})

const dashboardNav: NavSection[] = [
  {
    buttons: [{ label: 'Overview', to: '/liquidity-launch-designer/dashboard#overview' }],
  },
  {
    title: 'Bonding Curve Design',
    buttons: [
      { label: '1. Configure Bonding Curve', to: '/liquidity-launch-designer/dashboard#configure-bonding-curve' },
      { label: '2. Performance Simulations', to: '/liquidity-launch-designer/dashboard#performance-simulations' },
    ],
  },
  {
    title: 'Post-Bonding Considerations',
    buttons: [{ label: '3. Configure LP', to: '/liquidity-launch-designer/dashboard#configure-lp' }],
  },
  {
    title: 'Summary',
    buttons: [{ label: '4. Additional Details', to: '/liquidity-launch-designer/dashboard#additional-details' }],
  },
]

const overviewNav: NavSection[] = [
  {
    title: 'Summary',
    buttons: [
      { label: '1. Overview', to: '/liquidity-launch-designer/confirmation#overview' },
      { label: '2. Price Vs Tokens Sold', to: '/liquidity-launch-designer/confirmation#price-vs-tokens-sold' },
      { label: '3. Liquidity Details', to: '/liquidity-launch-designer/confirmation#liquidity-details' },
      { label: '4. Static Liquidity Distribution', to: '/liquidity-launch-designer/confirmation#liquidity-distribution' },
      { label: '5. What’s Next?', to: '/liquidity-launch-designer/confirmation#whats-next' },
    ],
  },
]
</script>

<template>
  <NuxtLoadingIndicator />
  <NuxtLayout name="dashboard" v-bind="$attrs">
    <template #header>
      <div class="flex gap-2 items-center">
        <div>
          <img
            :src="String('/icons/market-maker-monitoring.svg')"
            width="32"
            height="32"
            alt="Liquidity & Launch Designer"
          >
        </div>
        <div class="text-sm font-semibold flex-shrink-0">
          Liquidity & Launch Designer
        </div>
      </div>
    </template>

    <template #sidebar>
      <div class="h-full border-r border-forgd-bgd-600 w-[228px]">
        <nav
          aria-label="Exchange Listing Navigation"
          class="flex flex-col py-4 px-2 flex-shrink-0 sticky top-0 gap-1"
        >
          <template v-for="(section, index) in navSections" :key="section.title || section.buttons[0].label">
            <span v-if="section.title" class="w-full text-xs font-normal text-neutral-700 mb-1">
              {{ section.title }}
            </span>
            <UButton
              v-for="button in section.buttons"
              :key="button.to"
              :to="button.to"
              color="gray"
              variant="ghost"
              exact-hash
              active-class="bg-neutral-400"
              class="w-full h-8.5 rounded hover:bg-neutral-400 p-2"
            >
              <span class="text-xs whitespace-nowrap text-gray-600 font-normal">
                {{ button.label }}
              </span>
            </UButton>

            <div v-if="index < navSections.length - 1" class="border-b border-forgd-bgd-600 my-3" />
          </template>
        </nav>
      </div>
    </template>

    <div class="py-5">
      <slot />
    </div>
  </NuxtLayout>
</template>
